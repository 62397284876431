import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {



  mainColl: any = [];


  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Cart";

  fromdate: any = null;
  todate: any = null;


  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: 'UserType', field: 'usertype', sortable: true, filter: true },
    { headerName: 'CartDate', field: 'createdon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
    { headerName: 'CartGenerateOn', field: 'generatedon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
    { headerName: 'ProductCount', field: 'productcount', sortable: true, filter: true },
    { headerName: 'QtyTotal', field: 'qtytotal', sortable: true, filter: true },
    { headerName: 'GrandTotal', field: 'grandtotal', sortable: true, filter: true, valueFormatter: params => params.data.grandtotal.toFixed(2) },
    { headerName: 'Coupon', field: 'couponcode', sortable: true, filter: true },
    { headerName: 'Area', field: 'areaname', sortable: true, filter: true },
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY

  constructor(private appService: DhukanDataService, public router: Router,
    private confirmationDialogService: ConfirmationDialogService, private datePipe: DatePipe,
    private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {

      this.fromdate = new Date();
      this.todate = new Date();

      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetReportListView();
  }

  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }

  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  //Event  
  fnActionEditClick() {
    if (!this.gridApi) {
      return;
    }

    if (this.gridApi.getSelectedRows().length > 0) {
      var data = this.gridApi.getSelectedRows()[0];
      this.fnEditCart(data);
    }
  }

  fngetReportListView() {
    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'fromdate': this.fnFormatDatetime(this.fromdate),
      'todate': this.fnFormatDatetime(this.todate),
      'modifiedby': this.loginService.fnGetModUserId(),
    }
    this.appService.getCartListByAdmin(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.mainColl = res.json().result;
            this.fnSetDataTable();

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }




  fnEditCart(deptBO) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptBO.cartid,
        }
      }

      this.router.navigate(['/AddCart'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  private fnSetDataTable() {

    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }


  }


  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }

  fnExportData() {
    try {

      // const params = {
      //   columnGroups: true,
      //   allColumns: true,
      //   fileName: 'cartlist',
      // };
      // this.gridApi.exportDataAsCsv(params);
      const params = {
        columnGroups: true,
        allColumns: true,
        fileName: 'cartlist',      
      };
      this.loginService.fnExportdataCsv(params,this.gridApi)
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnMomentDateFormatter(params) {
    if (params.value) {
      return moment(params.value).utcOffset('+02:30',false).format('DD-MM-YYYY');//hh:mm a  
    }
    else {
      return '';
    }
  }

  fnMomentDateTimeFormatter(params) {
    if (params.value) {
      return moment(params.value).utcOffset('+02:30',false).format('DD-MM-YYYY hh:mm a');//  
    }
    else {
      return '';
    }

  }

  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}
