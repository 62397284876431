import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ZoomproductComponent } from '../orders/zoomproduct/zoomproduct.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/internal/Subscription';
declare var $ :any;

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit,AfterViewInit, OnDestroy {


  bannertypeColl:any=[];
  bannertypeid=0

  bannerColl:any=[];


  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="Banner";

  
  
  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: 'Banner Name', field: 'bannername', sortable: true, filter: true },
    { headerName: 'Link Type', field: 'linktype', sortable: true, filter: true },
    { headerName: 'Order By', field: 'orderby', sortable: true, filter: true },    
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY

  constructor( private appService: DhukanDataService,  private productFindService: NgbModal,public router: Router,private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService ,private loginService:LoginDataService) {
      this.fnServiceChanges();
  }

   
      //User Check
  fnServiceChanges(){
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
              if(this.loginService.fnCheckUser()){                
                  this.fnCheckPrivilage();
                  if(this.isview){
                    this.isloading=true;
                    this.fnOnInitForm();
                  }
                  else{
                    this.fnUserFailedForm();
                  }    
              }
              else{
                this.fnUserFailedForm();
              }
        });
  }
    
      


  ngOnInit() {
    try{
      
     

    if(this.loginService.fnCheckUser()){         
      this.fnCheckPrivilage();
      if(this.isview){
        this.isloading=true;
        this.fnOnInitForm();
      }
      else{
        this.fnUserFailedForm();
      }          
    }
    else{
      this.loginService.fnGetUserRoleList();
    }
    
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }


        
  fnOnInitForm(){
    this.fngetBannerType();  
   
  }
   
  
  
  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  


  ngAfterViewInit(): void {
    
  }

  ngOnDestroy(): void {
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }
  
  
  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;  
    
    //this.gridApi.refreshClientSideRowModel('filter') 
  }

  
  //Event  
  fnActionEditClick() {
    if (!this.gridApi) {
      return;
    }
    
    if (this.gridApi.getSelectedRows().length > 0 ) {
      var data = this.gridApi.getSelectedRows()[0];
      this.fnEditBanner(data.bannerid);
   }   
  }

  fnActionDeleteClick() {
    if (!this.gridApi) {
      return;
    }
    
    if (this.gridApi.getSelectedRows().length > 0 ) {
      var data = this.gridApi.getSelectedRows()[0];
      this.fnDeleteConfirm(data.bannerid);
   }   
  }

  fnActionExportClick()  {
    // this.gridApi.exportDataAsCsv(params);
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'mainbannerlist',      
    };

    this.loginService.fnExportdataCsv(params,this.gridApi)
  }


  fngetBannerType() {        
    var data = {
      'bannertypeid':0,            
    }
    this.appService.getBannerType(data)
    .subscribe(
      (res)=>{                  
        this.bannertypeColl=res.json().result; 
                  
        if(this.bannertypeColl.length>0 ){
          this.bannertypeid=this.bannertypeColl[0].bannertypeid;
        }



        this.route.queryParams.subscribe(params => {   
          if(params.bannertypeid!=undefined){
            let id=Number( params.bannertypeid);
            this.bannertypeid =id; 
            
          }           
        });

        this.fngetBanner();    
    },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }

  

  fngetBanner() {   
    if(this.showProgress){
      return;
    }

    this.fnShowProgress();
      var data = {
            'bannerid':0,
            'bannertypeid': this.bannertypeid,            
      }
      this.appService.getBanner(data)
          .subscribe(
            (res)=>{                  
              this.fnHideProgress();
              if(res.json().status==200){                          
                this.bannerColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
          },
            (err)=>{     
              this.fnHideProgress();     
              this.toastr.error('Error', err);
            })
  }

  fnAddBanner(){
    try{
      let navigationExtras: NavigationExtras = {
        queryParams: {         
          'bannertypeid':this.bannertypeid,
              
        }
      }

      this.router.navigate(['/AddBanner'],navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditBanner(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
          'bannertypeid':this.bannertypeid        
        }
        }
  
      this.router.navigate(['/AddBanner'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete banner?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteBanner(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteBanner(deptid){
       var data = {
         'bannerid': deptid,            
       }
      this.appService.fnDeleteBanner(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status==200){   
              this.toastr.success('', " The banner has been deleted successfully.");  
                             
              this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));
              
              this.fngetBanner();
            }
            else if(res.json().status==400){                          
              this.toastr.error('Error', res.json().err_field);
            }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

  private fnSetDataTable(){
   
    if (this.gridApi) {
      console.log(this.gridApi)
      
     // this.gridApi.sizeColumnsToFit();
    }
  }
    

 fnGetDescription(id){
  const index = this.bannerColl.findIndex(sku => sku.bannerid === id  ); 

  if(index){
    return this.bannerColl[index].bannername;
  }
  else{
    return "";
  }

}

fnInsertActivityLog(action,refno,desc){
try{
  var data={
    logtype:action,
    logat:this.formname,
    refnum:refno,
    logdescr:desc,
    logby:this.loginService.fnGetModUserId(),
  }


  this.appService.fnInsertActivity(data).subscribe(
    (res)=>{
    },
    (err)=>{
   
    });

}catch{

}
}


//Zoom Pic
fnShowZoomPic(picurl){
  const modalRef = this.productFindService.open(ZoomproductComponent ,{ size: <any>'l' },);
  modalRef.componentInstance.doctype ="IMAGE" ;
  modalRef.componentInstance.picurl=picurl;  
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
 
   
  });
      
}



  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }

  
  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }
}
