import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
declare var $ :any;

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.css']
})
export class SubcategoryComponent implements OnInit ,AfterViewInit, OnDestroy {
 

  subcategoryColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="Subcategory";

  
  
  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: 'Subcategory', field: 'subcategoryname', sortable: true, filter: true },
    { headerName: 'Category', field: 'categoryname', sortable: true, filter: true },    
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY
  
  constructor(private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,private loginService:LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges(){
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){                
              this.fnCheckPrivilage();
              if(this.isview){
                this.isloading=true;
                this.fnOnInitForm();
              }
              else{
                this.fnUserFailedForm();
              }    
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }


  ngOnInit() {
    try{
    

      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

      
  fnOnInitForm(){
    this.fngetSubCategory();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }

  ngAfterViewInit(): void {
  
  }

  ngOnDestroy(): void {
   
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }

  fngetSubCategory() {   
           
    if(this.showProgress){
      return;
    }

    this.fnShowProgress();
      var data = {
            'subcategoryid': 0,            
            'categoryid':0
      }
      this.appService.getSubCategory(data)
          .subscribe(
            (res)=>{      
              this.fnHideProgress();  
              if(res.json().status==200){                                          
                this.subcategoryColl=res.json().result;
                this.fnSetDataTable();             
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
          },
            (err)=>{          
              this.fnHideProgress();
              this.toastr.error('Error', err);
            })
  }

  fnAddSubCategory(){
    try{
      this.router.navigate(['/AddSubCategory']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditSubCategory(id){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': id,
        }
        }
  
      this.router.navigate(['/AddSubCategory'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(id) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete subcategory?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteSubCategory(id)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteSubCategory(id){
       var data = {
         'subcategoryid': id,            
       }
      this.appService.fnDeleteSubCategory(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
              this.toastr.success('', " The subcategory has been deleted successfully.");                 

              this.fnInsertActivityLog('DELETE',id,this.fnGetDescription(id));

              this.fngetSubCategory();
            }else{
              this.toastr.error('',res.json().err_field);   
            }
            
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

  private fnSetDataTable(){
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  
  
 //Grid
 fnGridReady(params) {
  this.gridApi = params.api;
  this.gridColumnApi = params.columnApi;   
}

  
fnActionEditClick() {
  if (!this.gridApi) {
    return;
  }
  
  if (!this.isedit) {
    return;
  }

  if (this.gridApi.getSelectedRows().length > 0 && this.isedit) {
    var data = this.gridApi.getSelectedRows()[0];


    this.fnEditSubCategory(data.subcategoryid);
 }   
}

  fnActionDeleteClick() {
    if (!this.gridApi) {
      return;
    }
    
    if (!this.isdelete) {
      return;
    }

    
  if (this.gridApi.getSelectedRows().length > 0) {
    var data = this.gridApi.getSelectedRows()[0];
    this.fnDeleteConfirm(data.subcategoryid);
  }   
}

fnActionExportClick()  {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'subcategorylist',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.loginService.fnExportdataCsv(params,this.gridApi)
}
  
      
  fnPrintData(){
    try{
    
                        
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportData(){
    try{
    
                         
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }
  
  
  
fnGetDescription(id){
  const index = this.subcategoryColl.findIndex(sku => sku.subcategoryid === id  ); 

  if(index){
    return this.subcategoryColl[index].subcategoryname;
  }
  else{
    return "";
  }

}

fnInsertActivityLog(action,refno,desc){
try{
  var data={
    logtype:action,
    logat:this.formname,
    refnum:refno,
    logdescr:desc,
    logby:this.loginService.fnGetModUserId(),
  }


  this.appService.fnInsertActivity(data).subscribe(
    (res)=>{
    },
    (err)=>{
   
    });

}catch{

}
}




  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }


}
