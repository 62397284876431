import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class RefundComponent implements OnInit {

  refundColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
  isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="Refund";

  filterby:String="PENDINGREFUND";
  isselectlist:boolean=false;


  fireshipment: any=[];


  
  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: 'RefundNo', field: 'refundno', sortable: true, filter: true },
    { headerName: 'OrderNo', field: 'orderno', sortable: true, filter: true },
    { headerName: 'EntryDate', field: 'createdon', sortable: true, filter: true },
    { headerName: 'Customer', field: 'firstname', sortable: true, filter: true },
    { headerName: 'Mode', field: 'refundcode', sortable: true, filter: true },
    { headerName: 'Paymode', field: 'paymode', sortable: true, filter: true },
    { headerName: 'RefundAmount', field: 'refundamt', sortable: true, filter: true },
    { headerName: 'Status', field: 'refundno', sortable: true, filter: true },
    { headerName: 'RefundPaymode', field: 'refundpaymode', sortable: true, filter: true },
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY

  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,private loginService:LoginDataService,
    private productFindService: NgbModal)
     {  this.fnServiceChanges(); }

     //User Check
    fnServiceChanges(){
      this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
            if(this.loginService.fnCheckUser()){                
                this.fnCheckPrivilage();
                if(this.isview){
                  this.isloading=true;
                  this.fnOnInitForm();
                }
                else{
                  this.fnUserFailedForm();
                }    
            }
            else{
              this.fnUserFailedForm();
            }
      });

    }
  
   
 


  ngOnInit() {
    try{
     
      
     
      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
       this.loginService.fnGetUserRoleList();          
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){  
    this.fngetRefundView();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  

  ngAfterViewInit(): void {
   
  }

  ngOnDestroy(): void {
   
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }
  
  
  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  fnActionEditClick() {
    if (!this.gridApi) {
      return;
    }

    if (!this.isedit) {
      return;
    }

    if (this.gridApi.getSelectedRows().length > 0 && this.isedit) {
      var data = this.gridApi.getSelectedRows()[0];


      this.fnEditRefund(data.refundid);
    }
  }

  fnActionExportClick() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'refundlist',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.loginService.fnExportdataCsv(params,this.gridApi)
  }


  fngetRefundView() {   
    if(this.showProgress){
      return;
    }
    this.isselectlist=false;
    this.fnShowProgress();
      var data = {
        filterby:this.filterby,
        modifiedby:this.loginService.fnGetModUserId(), 
      }
      this.appService.getRefundByListview(data)
          .subscribe(
            (res)=>{           
              this.fnHideProgress();       
              if(res.json().status==200){                          
                this.refundColl=res.json().result;
                this.fnSetDataTable();

              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }                          
          },
            (err)=>{          
              this.fnHideProgress();       
              this.toastr.error('Error', err);
            })
  }


  fnAddRefund(){
    try{
        
  
      this.router.navigate(['/AddRefund']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

 
 
  fnEditRefund(orderid){
    try{
        let navigationExtras: NavigationExtras = {
          queryParams: {
            'id': orderid,            
          }
        }
  
      this.router.navigate(['/AddRefund'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnSetDataTable(){
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  
  
  }

  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }
  

  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }







}
