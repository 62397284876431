import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-reason',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.css']
})
export class ReasonComponent implements OnInit ,AfterViewInit, OnDestroy {
 

  reasonColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="Reason";

  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: 'Reason', field: 'reason', sortable: true, filter: true },    
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };
  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY
  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,private loginService:LoginDataService) { this.fnServiceChanges(); }

   //User Check
   fnServiceChanges(){
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){                
              this.fnCheckPrivilage();
              if(this.isview){
                this.isloading=true;
                this.fnOnInitForm();
              }
              else{
                this.fnUserFailedForm();
              }    
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }


  ngOnInit() {
    try{
  
    

      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetReason();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  


  ngAfterViewInit(): void {
 
  }

  ngOnDestroy(): void {
   
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }
  
   //Grid
   fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  fnActionEditClick() {
    if (!this.gridApi) {
      return;
    }

    if (!this.isedit) {
      return;
    }

    if (this.gridApi.getSelectedRows().length > 0 && this.isedit) {
      var data = this.gridApi.getSelectedRows()[0];


      this.fnEditReason(data.reasonid);
    }
  }

  fnActionDeleteClick() {
    if (!this.gridApi) {
      return;
    }

    if (!this.isdelete) {
      return;
    }


    if (this.gridApi.getSelectedRows().length > 0) {
      var data = this.gridApi.getSelectedRows()[0];
      this.fnDeleteConfirm(data.reasonid);
    }
  }

  fnActionExportClick() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'reasonlist',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.loginService.fnExportdataCsv(params,this.gridApi)
  }

  fngetReason() { 
           
    if(this.showProgress){
      return;
    }
      
    this.fnShowProgress();
      var data = {
            'reasonid': 0,            
      }
      this.appService.getReason(data)
          .subscribe(
            (res)=>{             
  
              this.fnHideProgress();
              if(res.json().status==200){                          
                this.reasonColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
             
          },
            (err)=>{          
              this.fnHideProgress();
              this.toastr.error('Error', err);
            })
  }

  fnAddReason(){
    try{
      this.router.navigate(['/AddReason']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditReason(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
        }
  
      this.router.navigate(['/AddReason'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Reason?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteReason(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteReason(deptid){
       var data = {
         'reasonid': deptid,            
       }
      this.appService.fnDeleteReason(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
             this.toastr.success('', " The Reason has been deleted successfully.");                 

             this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));

              this.fngetReason();
            }else{
              this.toastr.error('',res.json().err_field);   
            }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

     

  private fnSetDataTable() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }



  
      
  fnPrintData(){
    try{
    
                          
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportData(){
    try{
    
                       
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }


  fnGetDescription(id){
    const index = this.reasonColl.findIndex(sku => sku.reasonid === id  ); 

    if(index){
      return this.reasonColl[index].reason;
    }
    else{
      return "";
    }

}
  
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}




  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }



}
