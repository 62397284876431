import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageorderComponent } from '../page/pageorder/pageorder.component';
import { ProductfilterComponent } from './productfilter/productfilter.component';
import { ZoomproductComponent } from '../orders/zoomproduct/zoomproduct.component';
import { ImageformatterComponent } from '../grid/imageformatter/imageformatter.component';
import { ButtonformatterComponent } from '../grid/buttonformatter/buttonformatter.component';
import { ImportproductComponent } from './importproduct/importproduct.component';
import { ProductoutofstockComponent } from './productoutofstock/productoutofstock.component';
declare var $: any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  productColl: any = [];
  deptColl: any = [];
  categoryColl: any = [];
  subcategoryColl: any = [];
  brandColl: any = [];
  warehouseColl: any = [];
  dtOptions: {};


  paradeptid: number = null;
  paracategoryid: number = null;
  parasubcategoryid: number = null;
  parabrandid: number = null;


  deptid: number = null;
  categoryid: number = null;
  subcategoryid: number = null;
  brandid: number = null;
  warehouseid: number = null;
  published: string = "";
  inactive: string = "";
  sku: string = "";
  productname: string = "";
  isselectlist: boolean = false;
  productgroupid: number = null;
  isshowall: boolean = false;

  stockstatus: string = "";

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;
  isadminuser: boolean = false;

  isviewallproduct: boolean = false;
  isimportnewproduct: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Product";

  isshowfilter: boolean = true;

  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: 'Pic', field: 'picurl', width: 100, sortable: false, cellRendererFramework: ImageformatterComponent, pinned: 'left' },
    { headerName: 'Sku', field: 'sku', type: "stringColumn", width: 110, minWidth: 110, sortable: true, filter: true, pinned: 'left' },//,pinned: 'left'
    { headerName: 'Code', field: 'productcode', sortable: true, filter: true, pinned: 'left' },
    { headerName: 'Name', field: 'productname', width: 260, minWidth: 260, sortable: true, filter: true, pinned: 'left' },
    { headerName: 'Department', field: 'deptname', width: 120, minWidth: 120, sortable: true, filter: true },
    { headerName: 'Category', field: 'categoryname', width: 120, minWidth: 120, sortable: true, filter: true },
    { headerName: 'SubCategory', field: 'subcategoryname', width: 150, minWidth: 150, sortable: true, filter: true },
    { headerName: 'Brand', field: 'brandname', width: 150, minWidth: 150, sortable: true, filter: true },
    // { headerName: 'MRP', field: 'mrp', sortable: true, filter: true },
    { headerName: 'Price', field: 'sellingprice', sortable: true, filter: true },
    { headerName: 'Stock', field: 'stock', sortable: true, filter: true },
    { headerName: 'Published', field: 'published', sortable: true, filter: true },
    { headerName: 'OutOfStock', field: 'outofstock', sortable: true, filter: true },
    { headerName: 'Status', field: 'activestatus', sortable: true, filter: true },
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 2,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
    sideBar: 'columns',

  };

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY

  constructor(private appService: DhukanDataService, public router: Router, private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService, private productFindService: NgbModal,
    private toastr: ToastrService, private loginService: LoginDataService) {

    this._routerSub = this.route.queryParams.subscribe(params => {

      if (params.deptid !== undefined) {
        this.paradeptid = params.deptid
      }

      if (params.categoryid !== undefined) {
        this.paracategoryid = params.categoryid
      }

      if (params.subcategoryid !== undefined) {
        this.parasubcategoryid = params.subcategoryid
      }

      if (params.brandid !== undefined) {
        this.parabrandid = params.brandid
      }

      if (params.productgroupid !== undefined) {
        this.productgroupid = params.productgroupid
      }


      if (params.published !== undefined) {
        this.published = params.published
      }

      if (params.inactive !== undefined) {
        this.inactive = params.inactive
      }

      if (params.sku !== undefined) {
        this.sku = params.sku
      }

      if (params.stockstatus !== undefined) {
        this.stockstatus = params.stockstatus
      }

      // if(params.showall!==undefined){
      //   this.isshowall = params.showall
      //   if(this.isshowall){
      //     if(this.paradeptid==0){
      //       this.paradeptid = null;
      //     }
      //   }
      // }

      if (params.productname !== undefined) {
        this.productname = params.productname
      }
    });


    this.fnServiceChanges();

  }

  //User Check
  fnServiceChanges() {
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }


      if (this.loginService.fnCheckAdminUser() === 1) {
        this.isadminuser = true;
      }
    });
  }


  ngOnInit() {
    try {


      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }



    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  ngOnDestroy(): void {

    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }

    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }

  fnOnInitForm() {
    this.fngetWarehouse();
    this.fngetDepartment();
    this.fngetBrand();

    if (this.loginService.fnCheckAdminUser() === 1) {
      this.isadminuser = true;
    }
  }

  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');

    this.isviewallproduct = this.loginService.fnCheckUserRoleAction("ViewAllProduct", 'VIEW');
    this.isimportnewproduct = this.loginService.fnCheckUserRoleAction("ImportNewProduct", 'VIEW');

  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }

  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  fnActionEditClick() {
    if (!this.gridApi) {
      return;
    }

    if (this.gridApi.getSelectedRows().length > 0 && this.isedit) {
      var data = this.gridApi.getSelectedRows()[0];


      this.fnEditProduct(data.productid);
    }
  }

  fnActionDeleteClick() {
    if (this.gridApi.getSelectedRows().length > 0) {
      var data = this.gridApi.getSelectedRows()[0];
      this.fnDeleteConfirm(data.productid);
    }
  }

  fnActionExportClick() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'productlist',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.loginService.fnExportdataCsv(params,this.gridApi)
  }



  fngetDepartment() {
    var data = {
      'deptid': 0,
    }
    this.appService.getDepartment(data)
      .subscribe(
        (res) => {
          this.deptColl = res.json().result;


          if (this.deptColl.length > 0 && !this.paradeptid) {
            this.deptid = this.deptColl[0].deptid;
          }

          if (this.paradeptid) {
            this.deptid = Number(this.paradeptid);
          }

          if (this.deptid) {
            this.fngetCategory();
          } else if (this.fnCheckEmptyCall() === false) {
            this.fngetProduct();
          }


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetCategory() {


    var data = {
      'categoryid': 0,
      'deptid': Number(this.deptid),
    }

    if (this.deptid > 0) {
      this.appService.getCategory(data)
        .subscribe(
          (res) => {

            let _result = res.json().result;

            this.categoryColl = [];

            this.categoryColl = res.json().result;


            if (this.categoryColl.length === 1 && Number(this.paracategoryid) === 0) {
              this.categoryid = this.categoryColl[0].categoryid;
            }

            if (this.paracategoryid) {
              this.categoryid = Number(this.paracategoryid);
            }

            if (this.parasubcategoryid) {
              this.subcategoryid = Number(this.parasubcategoryid);
            }

            if (this.parabrandid) {
              this.brandid = Number(this.parabrandid);
            }


            if (this.categoryid) {
              this.fngetSubCategory();

            }

            this.fngetProduct();



            // if(this.categoryColl.length===1){
            //   this.categoryid=this.categoryColl[0].categoryid;
            //   this.fngetSubCategory();
            // }

          },
          (err) => {
            this.toastr.error('Error', err);
          })
    }
    else {

      // this.categoryColl=[];
      // this.categoryid=null;
      // this.subcategoryColl=[];
      // this.subcategoryid=null;

    }
  }

  fngetSubCategory() {

    var data = {
      'subcategoryid': 0,
      'categoryid': this.categoryid,
    }

    if (this.categoryid > 0) {
      this.appService.getSubCategory(data)
        .subscribe(
          (res) => {
            this.subcategoryColl = res.json().result;


          },
          (err) => {
            this.toastr.error('Error', err);
          })
    }
    else {
      this.subcategoryColl = [];
      this.subcategoryid = null;
    }
  }

  fngetBrand() {
    var data = {
      'brandid': 0,
    }
    this.appService.getBrand(data)
      .subscribe(
        (res) => {
          this.brandColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetWarehouse() {
    var data = {
      'warehouseid': 0,
    }
    this.appService.getWarehouse(data)
      .subscribe(
        (res) => {
          this.warehouseColl = res.json().result;

          if (this.warehouseColl.length > 0) {
            this.warehouseid = this.warehouseColl[0].warehouseid;
          }

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetProduct() {

    if (this.showProgress) {
      return;
    }


    if (this.fnCheckEmptyCall() === true) {
      if (!this.isshowall) {
        if (this.deptColl.length > 0 && !this.paradeptid) {
          this.deptid = this.deptColl[0].deptid;
        }

        if (this.deptid) {
          this.fngetCategory();
        }
        return
      }
    }


    this.fnShowProgress();
    var data = {
      'productid': 0,
      'deptid': this.deptid,
      'categoryid': this.categoryid,
      'subcategoryid': this.subcategoryid,
      'brandid': this.brandid,
      'warehouseid': this.warehouseid,
      'published': this.published,
      'inactive': this.inactive,
      'stockstatus': this.stockstatus,
      'sku': this.sku,
      'productname': this.productname,
      'productgroupid': this.productgroupid
    }

    // console.log(data)
    this.appService.getProductByListView(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.productColl = res.json().result;
            this.fnHideProgress();
            this.fnSetDataTable();

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
            this.fnHideProgress();
          }


        },
        (err) => {
          this.toastr.error('Error', err);
          this.fnHideProgress();
        })
  }

  fnDeptchange() {


    this.paracategoryid = null;
    this.parasubcategoryid = null;

    this.categoryid = null;
    this.subcategoryid = null;

    this.categoryColl = [];
    this.subcategoryColl = [];

    this.fngetCategory();
    //this.fngetProduct()

    this.fnCurrentRouteChange();


  }

  fnCategoryChange() {
    this.parasubcategoryid = null;
    this.subcategoryid = null;

    this.subcategoryColl = [];

    this.fngetSubCategory();
    this.fngetProduct();


    this.fnCurrentRouteChange();
  }

  fnSubcategoryChange() {
    this.fngetProduct();

    this.fnCurrentRouteChange();
  }

  fnCurrentRouteChange() {

    const queryParams: Params = {
      'deptid': this.deptid,
      'categoryid': this.categoryid,
      'subcategoryid': this.subcategoryid,
      'brandid': this.brandid,
      'published': this.published,
      'inactive': this.inactive,
      'sku': this.sku,
      'productname': this.productname,
      'productgroupid': this.productgroupid,
      'showall': this.isshowall,
      'stockstatus': this.stockstatus
    };

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  fnCheckEmptyCall() {



    var _ischeck: boolean = false;

    if (this.sku !== '' || this.productname !== '') {
      this.deptid = null;
      this.categoryid = null;
      this.subcategoryid = null;
      this.brandid = null;

      this.categoryColl = [];
      this.subcategoryColl = [];

      this.fnCurrentRouteChange();
    }



    if (Number(this.deptid) === 0) {

      if (this.sku !== '' || this.productname !== '') {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return false;
    }

    return _ischeck;

  }

  fnShowAllClick() {


    this.deptid = null;
    this.categoryid = null;
    this.subcategoryid = null;
    this.brandid = null;
    this.categoryColl = [];
    this.subcategoryColl = [];

    this.fngetProduct();

  }

  fnAddProduct() {
    try {

      let navigationExtras: NavigationExtras = {
        queryParams: {
          'deptid': this.deptid,
          'categoryid': this.categoryid,
          'subcategoryid': this.subcategoryid,
          'brandid': this.brandid,
          'published': this.published,
          'inactive': this.inactive,
          'sku': this.sku,
          'productname': this.productname,
          'showall': this.isshowall,
          'stockstatus': this.stockstatus
        }
      }


      this.router.navigate(['/AddProduct'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnEditProduct(deptid) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
          'deptid': this.deptid,
          'categoryid': this.categoryid,
          'subcategoryid': this.subcategoryid,
          'brandid': this.brandid,
          'published': this.published,
          'inactive': this.inactive,
          'sku': this.sku,
          'productname': this.productname,
          'showall': this.isshowall,
          'stockstatus': this.stockstatus
        }
      }

      this.router.navigate(['/AddProduct'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Product?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteProduct(deptid)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteProduct(deptid) {
    var data = {
      'productid': deptid,
    }
    this.appService.fnDeleteProduct(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.toastr.success('', " The product has been deleted successfully.");

            this.fnInsertActivityLog('DELETE', deptid, this.fnGetDescription(deptid));

            this.fngetProduct();
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  fnShowFilter() {

    const modalRef = this.productFindService.open(ProductfilterComponent, { size: <any>'l' });
    modalRef.componentInstance.doctype = "Product Filter";

    modalRef.componentInstance.published = this.published;
    modalRef.componentInstance.inactive = this.inactive;
    modalRef.componentInstance.sku = this.sku;
    modalRef.componentInstance.productname = this.productname;
    modalRef.componentInstance.productgroupid = this.productgroupid;
    modalRef.componentInstance.stockstatus = this.stockstatus


    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


      this.published = receivedEntry.published;
      this.inactive = receivedEntry.inactive;
      this.sku = receivedEntry.sku;
      this.productname = receivedEntry.productname;
      this.productgroupid = receivedEntry.productgroupid;
      this.stockstatus = receivedEntry.stockstatus;

      if (this.sku !== '' || this.productname !== '') {
        this.deptid = null;
        this.categoryid = null;
        this.subcategoryid = null;
        this.brandid = null;


        this.categoryColl = [];
        this.subcategoryColl = [];

      }

      this.fngetProduct();
      this.fnCurrentRouteChange();
    })


  }

  fnRemoveFilter(type) {

    if (type === 'PUBLISHED') {
      this.published = "";
      this.fngetProduct();
      this.fnCurrentRouteChange();
    }
    else if (type === 'INACTIVE') {
      this.inactive = "";
      this.fngetProduct();
      this.fnCurrentRouteChange();
    }
    else if (type === 'SKU') {
      this.sku = "";
      this.fngetProduct();
      this.fnCurrentRouteChange();
    }
    else if (type === 'PRODUCTNAME') {
      this.productname = "";
      this.fnCurrentRouteChange();
      this.fngetProduct();
    }
    else if (type === 'STOCKSTATUS') {
      this.stockstatus = "";
      this.fnCurrentRouteChange();
      this.fngetProduct();
    }

    else {
      this.published = "";
      this.inactive = "";
      this.sku = "";
      this.productname = "";
      this.stockstatus = ""
      this.fnCurrentRouteChange();
      this.fngetProduct();

    }
  }


  private fnSetDataTable() {

  }

  //Bulk Publish
  fnBulkPublish() {
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Publish Product?')
      .then((confirmed) => {
        if (confirmed) {
          this.fnShowProgress();

          var totallength = 0;
          for (let i = 0; i < this.productColl.length - 1; i++) {
            if (this.productColl[i].ischeck) {
              totallength = totallength + 1;
            }
          }

          var loopindex = 0;

          for (let i = 0; i < this.productColl.length - 1; i++) {
            if (this.productColl[i].ischeck) {
              this.fnSetPublishSku(this.productColl[i].skuid, this.productColl[i].sku, this.productColl[i].productname, loopindex, totallength - 1)
              loopindex = loopindex + 1;
            }

          }

          // for(let abo of this.productColl){
          //   if(abo.ischeck){
          //     this.fnSetPublishSku(abo.skuid,abo.sku,abo.productname)
          //   }

          // }

          this.fnHideProgress();

        }
      }
      );



  }

  fnSetPublishSku(skuid, sku, productname, i, totallength) {

    var data = {
      skuid: skuid,
      ispublished: true,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetProductSkuPublish(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {

            // var _res=res.json().result[0][0];

            this.fnInsertActivityLog('PUBLISHED', skuid, productname);




            if (i === totallength) {
              this.fngetProduct();
            }

          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fnCheckValue(values: any, index) {

    this.productColl[index].ischeck = values.currentTarget.checked;

    this.isselectlist = false;
    const pindex = this.productColl.findIndex(sku => sku.ischeck === true);

    if (pindex > -1) {

      this.isselectlist = true;
    }

  }



  //Zoom Product
  fnShowZoomProduct(skuid) {
    const modalRef = this.productFindService.open(ZoomproductComponent, { size: <any>'l' },);
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.skuid = skuid;
    modalRef.componentInstance.fnGetProductInfo();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    });

  }




  fnImportBtn() {

    const modalRef = this.productFindService.open(ImportproductComponent, { size: <any>'l' });

    modalRef.componentInstance.warehouseid = this.warehouseid

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.ngOnInit();

    });

  }


  fnShowproductOutOfStock() {
    if (!this.gridApi) {
      return;
    }

    if (this.gridApi.getSelectedRows().length > 0 && this.isedit) {
      var data = this.gridApi.getSelectedRows()[0];

      const modalRef = this.productFindService.open(ProductoutofstockComponent, { size: <any>'l' });
      modalRef.componentInstance.doctype = "Product OutOfStock";

      modalRef.componentInstance.productid = data.productid;
      modalRef.componentInstance.skuid = data.skuid;
      modalRef.componentInstance.sku = data.sku;
      modalRef.componentInstance.productname = data.productname;
      modalRef.componentInstance.warehouseid = this.warehouseid;
      modalRef.componentInstance.fngetProduct()


      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

        if (receivedEntry === 'DONE') {
          this.fngetProduct();
          this.fnCurrentRouteChange();
        }

      })


    }



  }

  fnGetDescription(id) {
    const index = this.productColl.findIndex(sku => sku.productid === id);

    if (index) {
      return this.productColl[index].sku;
    }
    else {
      return "";
    }

  }

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
