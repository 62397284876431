import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription, interval } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ValueFormatterParams } from 'ag-grid-community';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

 
  orderColl:any=[];
  statusColl:any=[];
  tempstatusColl:any=[];

  statusid:number=0;
  totalordercount:number=0;

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
  isexport:boolean=false; 

   
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="Order";

  isorderplaced:boolean=false;
  isorderpacking:boolean=false;
  

  parafilterby: String = "ORDERPLACED";
  
  filterby:String="ORDERPLACED";
  isselectlist:boolean=false;
  fromdate:any=null;
  todate:any=null;

  
  orderplacedcount:number=0;
  topackcount:number=0;
  readytopickcount:number=0;
  assigncount:number=0;  
  pickedcount:number=0;
  deliverycount:number=0;
  receivedcount:number=0;
  cancelledcount:number=0;
  allcount:number=0;


  subscription: Subscription;
  intervalId: number;
  

  private gridApi;
  private gridColumnApi;

  columnDefs = [
    {headerName:'Order', field: 'orderno', sortable: true, filter: true },
    {headerName: 'Order Date', field: 'orderdate', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
    {headerName: 'Customer', field: 'customername', sortable: true, filter: true},
    {headerName: 'Mobile', field: 'mobile', sortable: true, filter: true},    
    {headerName: 'OrderCount', field: 'customerorder', sortable: true, filter: true},
    {headerName: 'Items', field: 'productcount', sortable: true, filter: true},
    {headerName: 'Total', field: 'grandtotal', sortable: true, filter: true, valueFormatter: params => params.data.grandtotal.toFixed(2)},
    {
      headerName: 'Order Status', field: 'orderno', sortable: true, filter: true,
      cellRenderer: (params) => {
        var deptBO = params.data;
        if (!deptBO.isconfirm && !deptBO.isreadytopick && !deptBO.isassigned && !deptBO.isdelivered && !deptBO.iscancelled) {
          return 'Order Placed'
        }
        else if (deptBO.isconfirm && !deptBO.isreadytopick && !deptBO.isassigned && !deptBO.isdelivered && !deptBO.iscancelled) { 
          return 'To Pack'
        }
        else if (deptBO.isreadytopick && !deptBO.isassigned &&  !deptBO.ispicked  && !deptBO.isdelivered && !deptBO.iscancelled) { 
          return 'Ready To Pick'
        }
        else if (deptBO.isassigned && !deptBO.ispicked  && !deptBO.isdelivered && !deptBO.iscancelled) { 
          return 'Assigned'
        }
        else if (deptBO.ispicked && !deptBO.isdelivered && !deptBO.iscancelled) { 
          return 'Picked'
        }
        else if (deptBO.isdelivered && !deptBO.isreceived && !deptBO.iscancelled) { 
          return 'Delivered'
        }
        else if (deptBO.isdelivered && deptBO.isreceived && !deptBO.iscancelled) { 
          return 'Received'
        }
        else if (deptBO.iscancelled) { 
          return 'Cancelled'
        }
        else {
          return '';
        }
      },
      cellStyle: function(params) {
        var deptBO = params.data;
        if (!deptBO.isconfirm && !deptBO.isreadytopick && !deptBO.isassigned && !deptBO.isdelivered && !deptBO.iscancelled) {
          return {color: '#f39c12'}
        }
        else if (deptBO.isconfirm && !deptBO.isreadytopick && !deptBO.isassigned && !deptBO.isdelivered && !deptBO.iscancelled) { 
          return {color: 'gray'}
        }
        else if (deptBO.isreadytopick && !deptBO.isassigned &&  !deptBO.ispicked  && !deptBO.isdelivered && !deptBO.iscancelled) { 
          return {color: 'darkblue'}
        }
        else if (deptBO.isassigned && !deptBO.ispicked  && !deptBO.isdelivered && !deptBO.iscancelled) { 
          return {color: 'gray'}
        }
        else if (deptBO.ispicked && !deptBO.isdelivered && !deptBO.iscancelled) { 
          return {color: 'sandybrown'}
        }
        else if (deptBO.isdelivered && !deptBO.isreceived && !deptBO.iscancelled) { 
          return {color: '#00c0ef'}
        }
        else if (deptBO.isdelivered && deptBO.isreceived && !deptBO.iscancelled) { 
          return {color: 'green'}
        }
        else if (deptBO.iscancelled) { 
          return {color: 'red'}
        }
        else {
          return '';
        }
      },     
    },
    {headerName: 'City', field: 'cityname', sortable: true, filter: true},
    {headerName: 'Area', field: 'areaname', sortable: true, filter: true},
    {headerName: 'Warehouse', field: 'warehousename', sortable: true, filter: true},
    {headerName: 'Delivery Type', field: 'deliverytype', sortable: true, filter: true },
    {headerName: 'ExpectDate', field: 'expectdeliverydt', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
    {headerName: 'Paymode', field: 'paymode', sortable: true, filter: true},
    {headerName: 'Confirmed', field: 'confirmbyname', sortable: true, filter: true},
    {headerName: 'ConfirmOn', field: 'confirmon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
    {headerName: 'ReadyToPick', field: 'readytopickbyname', sortable: true, filter: true},
    {headerName: 'ReadyToPickOn', field: 'readytopickon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },
    {headerName: 'Assigned', field: 'assignedbyname', sortable: true, filter: true },
    {headerName: 'AssignedOn', field: 'assignedon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter  },
    {headerName: 'Picked', field: 'pickedbyname', sortable: true, filter: true },
    {headerName: 'PickedOn', field: 'pickedon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },        
    {headerName: 'Delivered', field: 'deliveredbyname', sortable: true, filter: true },
    {headerName: 'DeliveredOn', field: 'deliveredon', sortable: true, filter: true, valueFormatter: this.fnMomentDateTimeFormatter },        
    {headerName: 'Received', field: 'receivedbyname', sortable: true, filter: true },
    {headerName: 'ReceivedOn', field: 'receivedon', sortable: true,  filter:true, valueFormatter: this.fnMomentDateTimeFormatter },        
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: false,
      enablePivot: false,
      sortable: true,
      filter: false,
    },
  };

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY
  _routerOrderSub = Subscription.EMPTY

  constructor( private appService: DhukanDataService,public router: Router,private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,
    private loginService:LoginDataService,private datePipe: DatePipe,)
     { 
          this._routerSub = this.route.queryParams.subscribe(params => {
          if(params.filterstatus!==undefined){
            this.parafilterby = params.filterstatus
            if (this.parafilterby != "") {
              this.filterby=this.parafilterby   
            }
            
          }  
        });  
    
      
          this.fromdate=new Date();
          this.todate=new Date();

          this.fnServiceChanges();
     }

 //User Check
 fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){                
            this.fnCheckPrivilage();
            if(this.isview){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }    
        }
        else{
          this.fnUserFailedForm();
        }
  });
  }  


  ngOnInit() {
    try{


      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
      
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    const source = interval(50000);
    this.subscription = source.subscribe(val => this.fngetStatus());

    this.fngetStatus();

    // this.intervalId = setInterval(this.fngetStatus, 10000);
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');

    this.isorderplaced=this.loginService.fnCheckUserRoleAction("OrderPlaced",'ALTER');
    this.isorderpacking=this.loginService.fnCheckUserRoleAction("Shipment",'CREATE');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }


  ngAfterViewInit(): void {
  
  }

  ngOnDestroy(): void {
   
  //  this.subscription && this.subscription.unsubscribe();



    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }

    
  if (this.subscription !== undefined) {
    this.subscription.unsubscribe();
  }

    
    clearInterval(this.intervalId);


  }
  
  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;   

    this.fnSetDataTable()
  }

  
  //Event  
  fnActionEditClick() {
    if (!this.gridApi) {
      return;
    }
    
    if (this.gridApi.getSelectedRows().length > 0 ) {
      var data = this.gridApi.getSelectedRows()[0];
      this.fnEditStatus(data.orderid);
   }   
  }

  fnActionExportClick()  {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'orderlist',      
    };

    this.loginService.fnExportdataCsv(params,this.gridApi)
   // this.gridApi.exportDataAsCsv(params);


    // this.gridApi.exportDataAsCsv({
    //   columnGroups: true,
    //   allColumns: true,
    //   fileName: 'orderlist',  
    //   processCellCallback: (params) => {
    //     const colDef = params.column.getColDef()
    //     // try to reuse valueFormatter from the colDef
    //     if (colDef.valueFormatter) {
    //       const valueFormatterParams: ValueFormatterParams = {
    //         ...params,
    //         data: params.node.data,
    //         node: params.node!,
    //         colDef: params.column.getColDef()
    //       };
    //       return colDef.valueFormatter(valueFormatterParams);
    //     }
    //     return params.value;
    //   },
    // });

  }

  

  fngetOrderListView() { 
    if(this.showProgress){
      return;
    }
      
    this.fnShowProgress();
      var data = {
            'statusid': this.statusid, 
            filterby:this.filterby,
            fromdate:this.fnFormatDatetime(this.fromdate), 
            todate:this.fnFormatDatetime(this.todate), 
            modifiedby:this.loginService.fnGetModUserId(),
         
      }
      this.appService.getOrderListView(data)
          .subscribe(
            (res)=>{      
              this.fnHideProgress();                   
              if(res.json().status==200){                          
                this.orderColl=res.json().result;
                this.fnSetDataTable();
                this.fnReloadStatus();
                this.fnCurrentRouteChange();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }                          
          },
            (err)=>{          
              this.fnHideProgress();       
              this.toastr.error('Error', err);
            })
  }

  fnCurrentRouteChange(){
    
    const queryParams: Params =  {   
      'filterstatus': this.filterby,     
    };

    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: queryParams, 
    
      });
  }

 
  fngetStatus() {   
    var data = {
          'statusid': 0,           
          'modifiedby':this.loginService.fnGetModUserId(),
    }
    this.appService.getOrderStatus(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                          
              // this.tempstatusColl=res.json().result;   
                       
              // this.statusColl=[];

              // for(let wbo of this.tempstatusColl){
              //   if(wbo.statustype==='PLACED' && this.isorderplaced)
              //   {
              //     this.statusColl.push(wbo)
              //   }
              //   if(wbo.statustype==='CONFIRMED' && this.isorderpacking)
              //   {
              //     this.statusColl.push(wbo)
              //   }

              //   if(wbo.statustype==='PACKED' && this.isorderpacking)
              //   {
              //     this.statusColl.push(wbo)
              //   }


              // }

              let result=res.json().result[0];
              this.orderplacedcount=result.placedcount;              
              this.topackcount=result.topackcount;              
              this.assigncount=result.assigncount;
              this.readytopickcount=result.readytopickcount;
              this.pickedcount=result.pickedcount;
              this.deliverycount=result.deliveredcount;
              this.receivedcount=result.receivedcount;
              this.cancelledcount=result.cancelledcount;
              this.allcount=result.allcount;


              
              // this.totalordercount=0;
              // for(let wbo of this.statusColl){
              //   this.totalordercount=wbo.totalcount;
              // }
            
              // if(this.statusColl.length ){
              //   this.statusid=this.statusColl[0].statusid;
              // }

              this.fngetOrderListView();
              
            }
            else if(res.json().status==400){                          
              this.toastr.error('Error', res.json().err_field);
            }
           
           
        },
          (err)=>{          
            this.toastr.error('Error', err);
        })
  }

  fnReloadStatus() {   
    var data = {
          'statusid': 0,   
          'modifiedby':this.loginService.fnGetModUserId(),         
    }
    this.appService.getOrderStatus(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                          
              // this.tempstatusColl=res.json().result;   
                       
              // this.statusColl=[];

              // for(let wbo of this.tempstatusColl){
              //   if(wbo.statustype==='PLACED' && this.isorderplaced)
              //   {
              //     this.statusColl.push(wbo)
              //   }
              //   if(wbo.statustype==='CONFIRMED' && this.isorderpacking)
              //   {
              //     this.statusColl.push(wbo)
              //   }

              //   if(wbo.statustype==='PACKED' && this.isorderpacking)
              //   {
              //     this.statusColl.push(wbo)
              //   }


              // }  
                      
              // this.totalordercount=0;
              // for(let wbo of this.statusColl){
              //   this.totalordercount=wbo.totalcount;
              // }

              let result=res.json().result[0];
              this.orderplacedcount=result.placedcount;              
              this.topackcount=result.topackcount;              
              this.assigncount=result.assigncount;
              this.readytopickcount=result.readytopickcount;
              this.pickedcount=result.pickedcount;
              this.deliverycount=result.deliveredcount;
              this.receivedcount=result.receivedcount;
              this.cancelledcount=result.cancelledcount;
              this.allcount=result.allcount;

                
            }
            else if(res.json().status==400){                          
              this.toastr.error('Error', res.json().err_field);
            }
          
          
        },
          (err)=>{          
            this.toastr.error('Error', err);
        })
  }

  fnChangeStatus(stBO){
   
    this.statusid=stBO.statusid;
    this.fngetOrderListView();
  }

  fnGetStatusTabActiveCalzz(stBO){
    var _result='';

    if(stBO){
      if(stBO.statusid===this.statusid){
        _result='activetab'  
      }
    }
    


    return _result;
  }

  fnEditStatus(deptid){
    try{
      if(!this.isedit){
        return
      }
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
          'sid': this.statusid,
        }
        }
  
      this.router.navigate(['/AddOrders'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnSetDataTable(){
  
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();

      var allColIds = this.gridColumnApi.getAllColumns()
          .map(column => column.colId);
          this.gridColumnApi.autoSizeColumns(allColIds);

      // If you want to resize all columns
      this.gridColumnApi.autoSizeAllColumns();


    }
  }

  public fnSearchDatatable(value){    
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {      
    //   dtInstance.search(value).draw();
    // });
  }


  
      
  
  fnFormatDatetime(dt){
    if(dt){
      if(dt===""){
        return null
      }
      else{
        return this.datePipe.transform(dt,"yyyy-MM-dd")  
      }
      
      }else {
      return null
    }
    
  }
  
  fnConvertDatetime(dt){
    if(dt){
      if(dt===""){
        return null
      }
      else{
        
  
        var dateParts = dt.split("-"); 
  
         var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 
  
         sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
  
         
         
         return sdate;
      }
      
      }else {
      return null
    }
   
  }

  fnMomentDateFormatter(params) {
    if (params.value) {
      return moment(params.value).utcOffset('+02:30',false).format('DD-MM-YYYY');//hh:mm a  
    }
    else {
      return '';
    }
  }

  fnMomentDateTimeFormatter(params) {
    if (params.value) {
      return moment(params.value).utcOffset('+02:30',false).format('DD-MM-YYYY hh:mm a ');//  
    }
    else {
      return '';
    }
    
  }

  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }


}
